import reactHtmlParser from 'react-html-parser'
import Connection from './Connection'
import { dequeueTaskService, requeueTaskService, syncOmegaT } from '../../Services/CodeCommitServices'
import { uploadFileInCurrentStep } from '../../Services/TaskViewServices'
import CreateCancelSubmitLoading from '../../Shared/CreateCancelSubmitLoading'
import React, { useState, useRef } from 'react'
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { copyFinalFromStep } from '../../Services/CodeCommitServices'
function Step(props) {

    const step = props.step
    const task = props.task
    const error = props.error
    const setError = props.setError
    const messages = props.messages
    const workflowSteps = props.workflowSteps
    const isOnThisStep = props.isOnThisStep
    const isFutureStep = props.isFutureStep
    const stepIndex = props.stepIndex
    const connections = props.connections
    const fetchTask = props.fetchTask
    const taskPermissions = props.taskPermissions
    const isTaskQueuedInCurrentStep = props.isTaskQueuedInCurrentStep
    const projectPermissions = props.projectPermissions
    const fileInput = useRef(null)
    const [openUploadDialog, setOpenUploadDialog] = useState(false);
    let formData = new FormData();
    // display role access under stepName
    const roles = props.roles
    const roleAccess = connections.map((connection) => connection.roleAccess).flat()
    const roleData = roleAccess?.map(el => roles.find(element => element.roleUuid === el))
    let uniqueRoles = [...new Set(roleData)]
    const roleAccesTitles = uniqueRoles.map((role) => role.roleTitle).sort().join(', ')

    const [isDequeueError, setIsDequeueError] = useState(false)
    const [isRequeueError, setIsRequeueError] = useState(false)
    const [isUploadingError, setIsUploadingError] = useState(false)
    const [uploadErrorMessage, setUploadErrorMessage] = useState(false)
    const [isDequeueLoading, setIsDequeueLoading] = useState(false)
    const [isRequeueLoading, setIsRequeueLoading] = useState(false)
    const [isUploadLoading, setIsUploadLoading] = useState(false)
    const [dequeueErrorMessage, setDequeueErrorMessage] = useState('')
    const [requeueErrorMessage, setRequeueErrorMessage] = useState('')
    const [isCopyFinalLoading, setIsCopyFinalLoading] = useState(false)
    const [isCopyFinalError, setIsCopyFinalError] = useState(false)
    const [copyFinalErrorMessage, setCopyFinalErrorMessage] = useState('')

    const [isSyncOmegaTLoading, setIsSyncOmegaTLoading] = useState(false)
    const [isSyncOmegaTError, setIsSyncOmegaTError] = useState(false)
    const [syncOmegaTErrorMessage, setSyncOmegaTErrorMessage] = useState('')

    const { stepHistory, targetDates } = task

    let givenName
    let familyName
    let updatedAt
    let localeTime
    let comment
    let latestStepHistory
    let latestUpdatedData
    const stepHistoryForTheStep = stepHistory.filter(stepHistoryItem => stepHistoryItem.fromStepUuid === step.stepUuid);
    if (stepHistoryForTheStep.length > 0) {
        latestStepHistory = stepHistoryForTheStep.pop()
        latestUpdatedData = latestStepHistory.updatedBy
    }

    if (latestUpdatedData) {
        givenName = latestUpdatedData.givenName
        familyName = latestUpdatedData.familyName
        updatedAt = latestUpdatedData.updatedAt
        const date = new Date(updatedAt)
        localeTime = date.toLocaleString([], { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' });
        comment = latestUpdatedData.comment
    }

    const handleDequeueTask = async () => {
        setIsDequeueLoading(true)
        try {
            await dequeueTaskService({ taskUuid: task.taskUuid, projectUuid: task.projectUuid })
            await fetchTask()
            setIsDequeueLoading(false)
        } catch (error) {
            console.error(error)
            setIsDequeueLoading(false)
            setIsDequeueError(true)
            setDequeueErrorMessage(error.response?.data ?? 'Something went wrong')
        }
    }

    const handleRequeueTask = async () => {
        setIsRequeueLoading(true)
        try {
            await requeueTaskService({ taskUuid: task.taskUuid, projectUuid: task.projectUuid })
            await fetchTask()
            setIsRequeueLoading(false)
        } catch (error) {
            console.error(error)
            setIsRequeueLoading(false)
            setIsRequeueError(true)
            setRequeueErrorMessage(error.response?.data ?? 'Something went wrong')
        }
    }

    const handleSyncOmegaT = async () => {
        setIsSyncOmegaTLoading(true)
        try {
            await syncOmegaT({ stepUuid: task.onStepUuid, projectName: task.projectName, taskUuid: task.taskUuid, localeUuid: task.localeUuid })
            setIsSyncOmegaTLoading(false)
        } catch (error) {
            console.error(error)
            setIsSyncOmegaTLoading(false)
            setIsSyncOmegaTError(true)
            setSyncOmegaTErrorMessage(error.response?.data ?? 'Something went wrong')
        }
    }

    const cleanSyncOmegaTErrorMessage = () => {
        setIsSyncOmegaTError(false)
        setSyncOmegaTErrorMessage('')
    }

    const handleUploadFile = async () => {
        if (task.stepHistory?.length === 0) {
            setIsUploadingError(true)
            setUploadErrorMessage('You cannot upload file if current task has no step history')
        }
        setIsUploadLoading(true)
        if (fileInput.current.files.length > 0) {
            for (let i = 0; i < fileInput.current.files.length; i++) {
                formData.append("files", fileInput.current.files[i])
            }
            try {
                await uploadFileInCurrentStep(task.taskUuid, formData)
                await fetchTask()
            } catch (error) {
                console.error(error)
                setIsUploadingError(true)
                setUploadErrorMessage(error.response?.data.messageHTML ?? 'Something went wrong')
            } finally {
                setOpenUploadDialog(false)
                setIsUploadLoading(false)
            }
        }
    }
    const handleCloseUploadDialog = () => {
        setOpenUploadDialog(false)
        setIsUploadingError(false)
        setIsUploadLoading(false)
        setUploadErrorMessage('')
    }

    const uploadDialogFooter = (
        <React.Fragment>
            <Button label="Cancel" icon="pi pi-times" outlined onClick={handleCloseUploadDialog} />
            <Button label="Upload" icon="pi pi-check" loading={isUploadLoading} onClick={handleUploadFile} />
        </React.Fragment>
    );

    const cleanDequeueErrorMessage = () => {
        setIsDequeueError(false)
        setDequeueErrorMessage('')
    }

    const cleanRequeueErrorMessage = () => {
        setIsRequeueError(false)
        setRequeueErrorMessage('')
    }

    let connectionsList = connections.map((connection, index) => {
        return (
            <Connection
                task={task}
                step={step}
                error={error}
                setError={setError}
                messages={messages}
                connection={connection}
                workflowSteps={workflowSteps}
                isOnThisStep={isOnThisStep}
                fetchTask={fetchTask}
                stepIndex={stepIndex}
                taskPermissions={taskPermissions}
                key={index}
                isTaskQueuedInCurrentStep={isTaskQueuedInCurrentStep}
            />)
    })
    const handleCopyFinal = async () => {
        try {
            setIsCopyFinalLoading(true)

            await copyFinalFromStep({
                fromStepUuid: step.stepUuid,
                projectName: task.projectName,
                localeUuid: task.localeUuid,
                taskUuid: task.taskUuid
            })
            setIsCopyFinalLoading(false)

        } catch (error) {
            console.error(error)
            setIsCopyFinalLoading(false)
            setIsCopyFinalError(true)
            setCopyFinalErrorMessage(error.response?.data ?? 'Something went wrong')
        }
    }

    const cleanCopyFinalErrorMessage = () => {
        setIsCopyFinalError(false)
        setCopyFinalErrorMessage('')
    }

    const isSameDay = (date1, date2) => {
        return date1.getFullYear() === date2.getFullYear() &&
            date1.getMonth() === date2.getMonth() &&
            date1.getDate() === date2.getDate();
    }

    const targetDateObject = targetDates.find(td => td.stepUuid === step.stepUuid);
    const isDoubleTranslation = step.stepName.toLowerCase().replace(/ /g, '-').includes('double-translation');
    const showExpectedStart = isFutureStep && !!targetDateObject?.prevStepFinalTargetDate && !!targetDateObject?.finalTargetDate && !targetDateObject?.duration
    const expectedStart = new Date(targetDateObject?.prevStepFinalTargetDate).toLocaleString(undefined, { year: 'numeric', month: '2-digit', day: '2-digit', timeZone: 'UTC' })
    const showDueBy = (isFutureStep || isOnThisStep) && !!targetDateObject?.finalTargetDate && (!targetDateObject?.duration || isOnThisStep)
    const dueBy = new Date(targetDateObject?.finalTargetDate).toLocaleString(undefined, { year: 'numeric', month: '2-digit', day: '2-digit', timeZone: 'UTC' })
    const showDuration = !!targetDateObject?.duration
    const showTimeSpent = !!targetDateObject?.duration && !!targetDateObject?.timeSpent
    const showTimePeriod = isFutureStep && !!targetDateObject?.duration
    let plannedDayDifference
    if (targetDateObject?.prevStepFinalTargetDate && targetDateObject?.finalTargetDate) {
        plannedDayDifference = Math.round((new Date(targetDateObject.finalTargetDate) - new Date(targetDateObject?.prevStepFinalTargetDate)) / (1000 * 60 * 60 * 24));
    }
    let isTodayOverdued = false;
    const finalTargetDate = new Date(targetDateObject.finalTargetDate);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    if (targetDateObject.finalTargetDate) {
        // For the current/future step, if finalTargetDate is smaller than current time and is not the same date, it is overdued.
        isTodayOverdued = (isFutureStep || isOnThisStep) && (finalTargetDate < today && !isSameDay(finalTargetDate, today));
    }
    const isOverDued = (!!plannedDayDifference && plannedDayDifference < 0) || isTodayOverdued
    const showDayDifference = isFutureStep && showDueBy && !!plannedDayDifference && plannedDayDifference < 0
    const timeSpent = !!targetDateObject?.timeSpent ? targetDateObject.timeSpent.toFixed(1) : 0
    return (
        <div className='flex flex-row'>
            <div
                className={`b--primary ba mt3 mb3 ml1 pl3 pr3 pt3 pb3 br2 ${isOverDued ? 'bg-red-200' : (isOnThisStep ? 'bg-light-secondary' : 'bg-white')}`}
                style={{ width: '200px' }}
            >
                {showExpectedStart && <div className='mt2'>
                    <div className='mt2'>
                        <div>Expected start:</div>
                        <div style={{ fontWeight: 'bold' }}>{expectedStart}</div>
                    </div>
                </div>}
                {showDuration && <div className='mt2'>
                    Duration: {targetDateObject?.duration} (days)
                </div>}
                {showTimeSpent && <div className='mt2'>
                    Time spent: {timeSpent} (days)
                </div>}
                {showDueBy && (
                    <div className='mt2'>
                        <div>Due by:</div>
                        <div style={{ fontWeight: 'bold' }}>{dueBy}</div>
                    </div>
                )}
                {showTimePeriod && (
                    <div className='mt2'>
                        <span style={{ fontWeight: 'bold' }}>{expectedStart}</span>
                        <span> to </span>
                        <div style={{ fontWeight: 'bold' }}>{dueBy}</div>
                    </div>
                )}
                {showDayDifference && (
                    <div className='mt2 text-right'>
                        {plannedDayDifference} (days)
                    </div>
                )}
            </div>

            <div className={`b--primary ba mt3 mb3 ml1 pl3 pr3 pt3 pb3 br2 ${isOnThisStep ? 'bg-light-secondary' : 'bg-white'}`} style={{ width: '800px' }}>
                <div className='flex flex-row justify-between'>
                    <div className='flex flex-column'>
                        {isTaskQueuedInCurrentStep && isOnThisStep ? <div className='b f4'>{step.stepName}(Queued)</div> : <div className='b f4'>{step.stepName}</div>}
                        {roleAccesTitles && <div>Roles: {roleAccesTitles}</div>}
                    </div>
                    {latestStepHistory && latestUpdatedData && <div className='flex flex-column'>
                        {projectPermissions?.canViewVerifiersName && <div>{givenName} {familyName}</div>}
                        <div>{localeTime}</div>
                    </div>}
                </div>
                {isOnThisStep &&
                    <>
                        <div>
                            <div className='mt2 dark-gray'>{reactHtmlParser(step.stepDescription)}</div>
                            <div className='flex flex-row'>
                                {connectionsList}
                            </div>
                        </div>
                    </>
                }
                <div class="flex justify-end gap-2">
                    {projectPermissions?.canUploadTaskFiles && isOnThisStep && <CreateCancelSubmitLoading
                        isSomethingWentWrong={isUploadingError}
                        isNowLoading={isUploadLoading}
                        createButtonText='Upload file'
                        errorMessage={uploadErrorMessage}
                        handleCreateResourceClick={() => { setOpenUploadDialog(true) }}
                        handleCloseErrorBoxClick={handleCloseUploadDialog}
                        buttonPosition='right'
                    />}
                    {projectPermissions?.canSyncOmegaT && isOnThisStep && step.queueEnabled &&
                        <CreateCancelSubmitLoading
                            isSomethingWentWrong={isSyncOmegaTError}
                            isNowLoading={isSyncOmegaTLoading}
                            createButtonText='Sync OmegaT'
                            errorMessage={syncOmegaTErrorMessage}
                            handleCreateResourceClick={handleSyncOmegaT}
                            handleCloseErrorBoxClick={cleanSyncOmegaTErrorMessage}
                            buttonPosition='right'
                        />
                    }
                    {projectPermissions?.canDequeueTask && isOnThisStep && isTaskQueuedInCurrentStep &&
                        <CreateCancelSubmitLoading
                            isSomethingWentWrong={isDequeueError}
                            isNowLoading={isDequeueLoading}
                            createButtonText='Dequeue task'
                            errorMessage={dequeueErrorMessage}
                            handleCreateResourceClick={handleDequeueTask}
                            handleCloseErrorBoxClick={cleanDequeueErrorMessage}
                            buttonPosition='right'
                        />}
                    {projectPermissions?.canDequeueTask && isOnThisStep && !isTaskQueuedInCurrentStep &&
                        <CreateCancelSubmitLoading
                            isSomethingWentWrong={isRequeueError}
                            isNowLoading={isRequeueLoading}
                            createButtonText='Requeue task'
                            errorMessage={requeueErrorMessage}
                            handleCreateResourceClick={handleRequeueTask}
                            handleCloseErrorBoxClick={cleanRequeueErrorMessage}
                            buttonPosition='right'
                        />}
                    {projectPermissions?.canCopyFinal && step.queueEnabled && !isDoubleTranslation && <CreateCancelSubmitLoading isSomethingWentWrong={isCopyFinalError}
                        isNowLoading={isCopyFinalLoading}
                        createButtonText='Copy translation to final'
                        errorMessage={copyFinalErrorMessage}
                        handleCreateResourceClick={handleCopyFinal}
                        handleCloseErrorBoxClick={cleanCopyFinalErrorMessage}
                        buttonPosition='right' />}
                </div>
                {comment && (
                    <div className='flex flex-column mt3 mb2'>
                        <span className='b'>Transition comment:</span>
                        <span style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>{comment}</span>
                    </div>
                )}
                <Dialog visible={openUploadDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Upload file" modal footer={uploadDialogFooter} onHide={handleCloseUploadDialog}>
                    <div className="confirmation-content">
                        <div className='mt1'>
                            <p className='b mb1'>{'Select files'}</p>
                            <div className='mt2'>
                                <input className='upload-files-button' type="file" name="files" ref={fileInput} multiple accept=".xlsx, .docx, .pdf, .doc, .xls, .csv, .xlsm" />
                                <span className='b dark-red' id='fileError' />
                            </div>
                        </div>
                    </div>
                </Dialog>
            </div>
        </div>
    )
}

export default Step