import AppService from './AppService'
const baseURL = '/api/codecommit'
const API = AppService.initAPI(baseURL)

export async function copyTranslationFile(serviceProps) {
    return await API.post(`copyTranslationFile`, serviceProps)
}

export async function copyTranslationFileSelf(serviceProps) {
    return await API.post(`copyTranslationFileSelf`, serviceProps)
}

export async function copyTranslationFileFinal(serviceProps) {
    return await API.post(`copyTranslationFileFinal`, serviceProps)
}

export async function copyFinalFromStep(serviceProps) {
    return await API.post(`copyFinalFromStep`, serviceProps)
}

export async function checkCommit(serviceProps) {
    return await API.post(`checkCommit`, serviceProps)
}

export async function checkCompletion(serviceProps) {
    return await API.post(`checkCompletion`, serviceProps)
}

export async function removeSourceService(serviceProps) {
    return await API.post('removeSourceService', serviceProps)
}

export async function addSourceAtNextStepService(serviceProps) {
    return await API.post('addSourceAtNextStepService', serviceProps)
}

export async function checkBorrowerTransitionDataService(serviceProps) {
    return await API.post('checkBorrowerTransitionDataService', serviceProps)
}

export async function dequeueTaskService(serviceProps) {
    return await API.post('dequeueTaskOnCurrentStep', serviceProps)
}

export async function requeueTaskService(serviceProps) {
    return await API.post('requeueTaskOnCurrentStep', serviceProps)
}

export async function generateDiffreportService(serviceProps) {
    return await API.post(`generateDiffreportService`, serviceProps)
}

export async function regenerateDiffreportService(serviceProps) {
    return await API.post(`regenerateDiffReportService`, serviceProps)
}

export async function copyBackwardsService(serviceProps) {
    return await API.post(`copyBackwardsService`, serviceProps)
}

export async function arrangeTmxFilesService(serviceProps) {
    return await API.post(`arrangeTmxFilesService`, serviceProps)
}

export async function checkTargetFilesService(serviceProps) {
    return await API.post(`checkTargetFilesService`, serviceProps)
}

export async function syncOmegaT(serviceProps) {
    return await API.post(`syncOmegaT`, serviceProps)
}